import { useState, useEffect } from 'react';
import { Button, Row, Col, Badge, Card, CardHeader, Input } from 'reactstrap';
import { Plus, Eye, Trash2 } from 'react-feather';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {DataTransformer, getScreenPermissions} from "../../utility/Utils";
import {useAuth, useCompanies, useLoader, useSelectedCompany} from "../../hooks";
import {ConfirmationModal, showErrorMessage, showSuccessMessage} from "../../components";
import AddTeamModal from "./AddTeamModal";
import {Network, Urls} from "../../apiConfiguration";
import {NavRoutes} from "../../common";



const EmployeeLeaveQuota = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [Teams, setTeams] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [officeLocations, setOfficeLocations] = useState([]);
  const { setLoading } = useLoader();
  const { user } = useAuth();
  const { companies } = useCompanies();
  const { company, setCompany } = useSelectedCompany();
  const [searchValues, setSearchValues] = useState({
    leave_type: 'all',
    office_location_id: 'all',
  });
  const [permissions, _] = useState(getScreenPermissions(user?.permissions, 'teams'));
  const navigate = useNavigate();

  const getEmployeeLeaves = async (obj, currentPage) => {
    setLoading(true);
    const response = await Network.post(
      `${Urls.getEmployeeLeavesFilters(company)}?page=${currentPage + 1}`,
      obj
    );
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setTeams(response.data.data.data);
    setTotalPages(response.data.data.total_pages);
  };

  useEffect(() => {
    getEmployeeLeaves(searchValues, currentPage);
  }, [apiCall, currentPage, company]);

  useEffect(() => {
    getDropDownData();
    getLocations();
  }, []);

  const getLocations = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getOfficeLocations);
    if (!response.ok) showErrorMessage(response.data.message);
    setLoading(false);
    setOfficeLocations(DataTransformer(response.data.office_locations, 'name', 'id'));
  };


  const columns = [
    {
      name: 'Employee Name',
      selector: (row) => row.employee,
    },
    {
      name: 'Leave Name',
      selector: (row) => row.leaveType,
    },

    {
      name: 'Office Location ',
      selector: (row) => row.officeLocation,
    },
    {
      name: 'Qouta',
      selector: (row) => row.qouta,
    },
    {
      name: '',
      selector: (row) => row.status,
      cell: (row) => {
        return (
          <Badge pill color={row.status ? 'success' : 'danger'}>
            {row.status ? 'Active' : 'Inactive'}
          </Badge>
        );
      },
    },

  ];

  const CustomPagination = () => {
    const handlePagination = (page) => {
      setCurrentPage(page.selected);
    };

    return (
      <ReactPaginate
        previousLabel=''
        nextLabel=''
        onPageChange={(page) => handlePagination(page)}
        pageCount={totalPages}
        breakLabel='...'
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        forcePage={currentPage}
        activeClassName='active'
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
      />
    );
  };

  const getDropDownData = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getLeaveTypes);
    setLoading(false);
    if (!response.ok) return showErrorMessage('Failed to load dropdown data');
    console.log(response.data);
    setProjectManagers(DataTransformer(response.data.data, 'name', 'id'));
  };

  return (
    <>
      <Card className='mt-2'>
        <CardHeader>
          <Row className='w-100'>
            <Col md={3} className='mt-2 mt-md-0'>
              <Select
                options={companies}
                placeholder='Select Leave Type'
                isDisabled={user?.changeCompany}
                value={companies.find((item) => item.value === company)}
                onChange={({ value }) => {
                  setCompany(value);
                }}
              />
            </Col>
            <Col md={4} className='mt-2 mt-md-0'>
              <Select
                isClearable
                options={projectManagers}
                placeholder='Select Leave Types'
                value={projectManagers.find(
                  (item) => item.value === searchValues.leave_type
                )}
                onChange={(value) => {
                  let obj = {};
                  if (value === null) {
                    obj = { ...searchValues, leave_type: '' };
                  } else {
                    obj = { ...searchValues, leave_type: value.value };
                  }
                  setSearchValues(obj);
                  setCurrentPage(0);
                  getEmployeeLeaves(obj, 0);
                }}
              />
            </Col>
            <Col md={4} className='mt-2 mt-md-0'>
              <Select
                  placeholder='Location'
                  options={officeLocations}
                  value={officeLocations.find(
                      (item) => item.value === searchValues.office_location_id
                  )}
                  isClearable
                  onChange={(value) => {
                    let obj = {};
                    if (value === null) {
                      obj = { ...searchValues, office_location_id: '' };
                    } else {
                      obj = { ...searchValues, office_location_id: value.value };
                    }
                    setSearchValues(obj);
                    setCurrentPage(0);
                    getEmployeeLeaves(obj, 0);
                  }}
              />
            </Col>

          </Row>
        </CardHeader>
        <div className='react-dataTable'>
          <DataTable
            noHeader
            columns={columns}
            data={Teams}
            pagination
            paginationComponent={CustomPagination}
          />
        </div>
      </Card>
    </>
  );
};

export default EmployeeLeaveQuota;
