import React, {useEffect, useState} from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
  Label,
  Form,
  Button,
} from "reactstrap";
import { useFormik } from "formik";
import Select from "react-select";

import { useAuth, useLoader } from "../../hooks";
import { Network, Urls } from "../../apiConfiguration";
import { showErrorMessage, showSuccessMessage } from "../../components";
import { WorkCategorySchema } from "../../validations";
import {DataTransformer} from "../../utility/Utils";

const OfficeLeaveModal = ({
  show,
  setShow,
  mode = "Add",
  setCheck,
  companies,
  initialValue = {
    name: "",
    notes: "",
    is_active: true,
  },
}) => {
  const { user } = useAuth();
  const initialValues = {
    ...initialValue,
    company_id: initialValue.company_id
      ? initialValue.company_id
      : user?.company_id,
  };

  const { setLoading } = useLoader();
  const [officeLocations, setOfficeLocations] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);

  const getLocations = async () => {
    const response = await Network.get(Urls.getOfficeLocations);
    setOfficeLocations(DataTransformer(response.data.office_locations, 'name', 'id'));
  }

  const getLeaveTypes = async () => {
    const response = await Network.get(Urls.getLeaveTypes);
    setLeaveTypes(DataTransformer(response.data.data, 'name', 'id'));
  }


  useEffect(() => {
    getLocations();
    getLeaveTypes()
  }, []);

  const onSubmit = async (data) => {
    const url =
      mode === "Add"
        ? Urls.manageOfficeLeaveTypes(data.company_id)
        : `${Urls.manageOfficeLeaveTypes(data.company_id)}/${initialValue.id}`;
    const request = mode === "Add" ? "post" : "patch";

    setLoading(true);
    const response = await Network[request](url, data);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage(response.data.message);
    setCheck((prev) => {
      return !prev;
    });
    setShow(false);
    resetValues();
  };

  const resetValues = () => {
    values.name = "";
    values.notes = "";
  };

  const { values, handleChange, handleSubmit, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      onSubmit,
    });

  const ErrorMessage = ({ name }) => {
    if (!touched[name]) return null;
    return <div className="text-danger fst-italic fw-bold">{errors[name]}</div>;
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => setShow(false)}
      className="modal-dialog-centered "
    >
      <ModalHeader className="bg-white" toggle={() => setShow(false)} />
      <ModalBody className="pb-3">
        <h2 className="text-center mb-2">{mode} Leave Type</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col tag="div" className="mt-1" sm={12}>
              <Label>Company*</Label>
              <Select
                options={companies}
                isDisabled={mode !== "Add" || user?.changeCompany}
                onChange={({ value }) =>
                  handleChange({
                    target: {
                      name: "company_id",
                      value,
                    },
                  })
                }
                onBlur={handleBlur}
                value={companies?.find(
                  (item) => item.value === values.company_id
                )}
              />
              <ErrorMessage name="company_id" />
            </Col>
            <Col tag="div" className="mt-1" sm={12}>
                <Label>Office Location*</Label>
                <Select
                    name='office_location_id'
                    className='react-select'
                    classNamePrefix='select'
                    options={officeLocations}
                    value={officeLocations.find(
                        (item) => item.value === values.office_location_id
                    )}
                    onChange={({ value }) => {
                      let event = {
                        target: {
                          name: 'office_location_id',
                          value,
                        },
                      };
                      handleChange(event);
                    }}
                    placeholder='Select Office Location'
                />
                <ErrorMessage touched={touched} name='office_location_id' errors={errors} />
            </Col>

            <Col tag="div" className="mt-1" sm={12}>
              <Label>Office Location*</Label>
              <Select
                  name='leave_type_id'
                  className='react-select'
                  classNamePrefix='select'
                  options={leaveTypes}
                  value={leaveTypes.find(
                      (item) => item.value === values.leave_type_id
                  )}
                  onChange={({ value }) => {
                    let event = {
                      target: {
                        name: 'leave_type_id',
                        value,
                      },
                    };
                    handleChange(event);
                  }}
                  placeholder='Select Leave Types'
              />
              <ErrorMessage touched={touched} name='leave_type_id' errors={errors} />
            </Col>
            <Col md={12} className="mt-1">
              <Label>Quota</Label>
              <Input
                  name="quota"
                  disabled={mode === "View"}
                  onChange={handleChange}
                  value={values.quota}
              />
            </Col>
            <Col md={12} className="mt-1">
              <Label>Year</Label>
              <Input
                  name="year"
                  disabled={mode === "View"}
                  onChange={handleChange}
                  value={values.year}
              />
            </Col>
            <Col
              tag="div"
              className=" mt-2 mb-1 d-flex justify-content-center align-items-center"
              sm={12}
            >
              {mode !== "View" && (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
              <Button
                onClick={() => setShow(false)}
                color="danger"
                className={`${mode !== "View" ? "ms-2" : ""}`}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default OfficeLeaveModal;
