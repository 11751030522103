import React from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
  Label,
  Form,
  Button,
} from "reactstrap";
import { useFormik } from "formik";
import Select from "react-select";

import { useAuth, useLoader } from "../../hooks";
import { Network, Urls } from "../../apiConfiguration";
import { showErrorMessage, showSuccessMessage } from "../../components";
import { WorkCategorySchema } from "../../validations";

const LeaveTypeModal = ({
  show,
  setShow,
  mode = "Add",
  setCheck,
  companies,
  initialValue = {
    name: "",
    notes: "",
    is_active: true,
  },
}) => {
  const { user } = useAuth();
  const initialValues = {
    ...initialValue,
    company_id: initialValue.company_id
      ? initialValue.company_id
      : user?.company_id,
  };

  const { setLoading } = useLoader();

  const onSubmit = async (data) => {
    const url =
      mode === "Add"
        ? Urls.manageLeaveTypes(data.company_id)
        : `${Urls.manageLeaveTypes(data.company_id)}/${initialValue.id}`;
    const request = mode === "Add" ? "post" : "patch";

    setLoading(true);
    const response = await Network[request](url, data);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage(response.data.message);
    setCheck((prev) => {
      return !prev;
    });
    setShow(false);
    resetValues();
  };

  const resetValues = () => {
    values.name = "";
    values.notes = "";
  };

  const { values, handleChange, handleSubmit, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      onSubmit,
    });

  const ErrorMessage = ({ name }) => {
    if (!touched[name]) return null;
    return <div className="text-danger fst-italic fw-bold">{errors[name]}</div>;
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => setShow(false)}
      className="modal-dialog-centered "
    >
      <ModalHeader className="bg-white" toggle={() => setShow(false)} />
      <ModalBody className="pb-3">
        <h2 className="text-center mb-2">{mode} Leave Type</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col tag="div" className="mt-1" sm={12}>
              <Label>Company*</Label>
              <Select
                options={companies}
                isDisabled={mode !== "Add" || user?.changeCompany}
                onChange={({ value }) =>
                  handleChange({
                    target: {
                      name: "company_id",
                      value,
                    },
                  })
                }
                onBlur={handleBlur}
                value={companies?.find(
                  (item) => item.value === values.company_id
                )}
              />
              <ErrorMessage name="company_id" />
            </Col>
            <Col tag="div" className="mt-1" sm={12}>
              <Label>Leave Type*</Label>
              <Input
                disabled={mode === "View"}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <ErrorMessage name="name" />
            </Col>

            <Col md={12} className="mt-1">
              <Label>Notes</Label>
              <Input
                name="notes"
                type="textarea"
                disabled={mode === "View"}
                onChange={handleChange}
                value={values.notes}
              />
            </Col>

            <Col tag="div" md={12} className="mt-2 d-flex align-items-center">
              <Label>Active</Label>
              <div className="form-switch form-check-success ms-1">
                <Input
                  disabled={mode === "View"}
                  type="switch"
                  checked={values.status}
                  id="switch-success"
                  name="status"
                  onChange={(e) => {
                    const value = e.target.checked;
                    let event = { target: { name: "status", value } };
                    handleChange(event);
                  }}
                  placeholder="Select position"
                  defaultChecked
                />
              </div>
            </Col>

            <Col
              tag="div"
              className=" mt-2 mb-1 d-flex justify-content-center align-items-center"
              sm={12}
            >
              {mode !== "View" && (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
              <Button
                onClick={() => setShow(false)}
                color="danger"
                className={`${mode !== "View" ? "ms-2" : ""}`}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default LeaveTypeModal;
